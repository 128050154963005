import PromptDialog from '@/components/PromptDialog.vue'
import vuetify from './vuetify'

const PromptPlugin = {
  install(Vue) {
    // Create a Vue instance to manage the prompt dialog
    const PromptInstance = new Vue({
      vuetify, // Add Vuetify instance
      data() {
        return {
          promptComponent: null
        }
      },
      methods: {
        mountIfNeeded() {
          if (this.promptComponent) return

          // Create a div to mount the prompt component
          const div = document.createElement('div')
          document.body.appendChild(div)

          // Create and mount the prompt component
          const ComponentClass = Vue.extend(PromptDialog)
          this.promptComponent = new ComponentClass({
            vuetify, // Pass Vuetify instance to the component
            parent: this // Set the parent to ensure proper inheritance
          })
          this.promptComponent.$mount(div)
        },
        prompt(options) {
          this.mountIfNeeded()
          return this.promptComponent.open(options)
        }
      }
    })

    // Add the $prompt method to Vue's prototype
    Vue.prototype.$prompt = (options) => {
      return PromptInstance.prompt(options)
    }
  }
}

export default PromptPlugin
