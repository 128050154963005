var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-tel-input',{attrs:{"styleClasses":"pt-2 pb-2 mb-8","mode":"international","dropdownOptions":{
                   showDialCodeInSelection: false,
                   showFlags: true,
                 },"inputOptions":{
                   placeholder: _vm.label,
                   autocomplete: 'on',
                   required: _vm.required,
                   label: _vm.label
                 },"validCharactersOnly":true,"enabledFlags":true,"enabledFormatting":true,"error-messages":_vm.phoneError ? ['Valid phone number is required'] : []},on:{"validate":_vm.validatePhone,"open":function($event){_vm.open = !_vm.open},"close":function($event){_vm.open = !_vm.open},"input":_vm.onPhoneInput},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.open ? '▲' : '▼'))])]},proxy:true}]),model:{value:(_vm.phoneValue),callback:function ($$v) {_vm.phoneValue=$$v},expression:"phoneValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }