import Vue from 'vue'
// import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore';

const initial_state = () => ({ 
    parcels: {},
    ds_last_fetch: {},
    order_prefixes: {},
    selected_data_source_id: '',
})

const state = initial_state()

const actions = {
  async search_parcels({ commit, state }, { query, data_source_id }) {
    if (!query || query.trim() === '') {
      // If query is empty, reset the state and fetch regular data
      commit('RESET_STATE');
      return await this.dispatch('fulfillments/fetch_parcels', { rows: 10 });
    }
    
    // Use the data source ID from the parameters or fall back to the state
    const dsId = data_source_id || state.selected_data_source_id;
    
    try {
      // Get a reference to the fulfillments collection for the selected data source
      const db = firebase.firestore();
      const fulfillmentsRef = db.collection('data_sources').doc(dsId).collection('fulfillments');
      
      // Create an array to hold all our query promises
      const queryPromises = [];
      
      // Search for fulfillment number (name field)
      const query_for_name = (q) => {
        q = (q || '').toString()
        const prefix = state.order_prefixes[dsId] || '#'
        return q[0] === prefix ? q : `${prefix}${q}`
      }

      queryPromises.push(
        fulfillmentsRef
          .orderBy('name')
          .startAt(query_for_name(query))
          .endAt(query_for_name(query) + '\uf8ff')
          .limit(50)
          .get()
      );
      
      // Search for customer name
      // We need to use a compound query for nested fields
      queryPromises.push(
        fulfillmentsRef
          .where('shipping_address.name', '>=', query)
          .where('shipping_address.name', '<=', query + '\uf8ff')
          .limit(50)
          .get()
      );
      
      // Search for tracking number
      queryPromises.push(
        fulfillmentsRef
          .orderBy('tracking_number')
          .startAt(query.toUpperCase())
          .endAt(query.toUpperCase() + '\uf8ff')
          .limit(50)
          .get()
      );
      
      // Execute all queries in parallel
      const querySnapshots = await Promise.all(queryPromises);
      
      // Combine and deduplicate results
      const searchResults = [];
      const seenIds = new Set();
      
      querySnapshots.forEach(snapshot => {
        snapshot.forEach(doc => {
          if (!seenIds.has(doc.id)) {
            seenIds.add(doc.id);
            searchResults.push({
              ...doc.data(),
              sf_fulfillment_id: doc.id
            });
          }
        });
      });
      
      // Sort by created_at in descending order (newest first)
      searchResults.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      
      // Update the state with search results
      commit('SET_PARCELS', { parcels: searchResults });
      
      // If no results found, log a message to the console
      if (searchResults.length === 0) {
        console.log(`No fulfillments found matching "${query}"`);
      } else {
        console.log(`Found ${searchResults.length} fulfillments matching "${query}"`);
      }
      
      return searchResults;
    } catch (error) {
      console.error('Error searching parcels:', error);
      throw error;
    }
  },
  
  async fetch_parcels({ commit, state }, { rows }){
    const limit = rows - (state.parcels[state.selected_data_source_id] || []).length
    console.log('limit', limit);
    if(limit < 1) return
    const ds_parcels = await firebase.firestore()
            .collection('data_sources')
            .doc(state.selected_data_source_id)
            .collection('fulfillments')
            .orderBy('updated_at', 'desc')
            .startAfter(state.ds_last_fetch[state.selected_data_source_id] || new Date('2999-01-01').toISOString())
            .limit(limit)
            .get()
            .then(async res => 
              {
                const docs = await Promise.all(res.docs.map(d => ({
                  ...d.data(),
                  // sf_ds_id: ds.id,
                  // sf_ds_name: ds.name,
                  sf_fulfillment_id: d.id
                })))
                return { parcels: docs, last_fetch: { date: docs.length && docs[docs.length - 1].updated_at }}
              }
              )
              console.log('ds_parcels', ds_parcels)
      const parcels = (state.parcels[state.selected_data_source_id] || [])
        .concat(ds_parcels.parcels)
      parcels.sort((a, b) => b.created_at - a.created_at) // TODO
      const last_fetches = { [state.selected_data_source_id]: ds_parcels.last_fetch.date }
      // ds_parcels.forEach(ds => {
      //   if(!ds.last_fetch.date) return
      //   last_fetches[ds.last_fetch.ds_id] = ds.last_fetch.date
      // })
      commit('SET_STATE', { ds_last_fetch: {
          ...state.ds_last_fetch,
          ...last_fetches,
          },
          order_prefixes: {
            ...state.order_prefixes,
            ...(parcels?.[0]?.name?.[0] ? 
              { 
                [state.selected_data_source_id]: parcels?.[0]?.name?.[0]
              } : {}
            )
          }
        })
      console.log('fetch_parcels', parcels.map(p => p.name))
      commit('SET_PARCELS', { parcels })
  }
}

const mutations = {
      SET_PARCELS(state, { parcels } ){
        Vue.set(state.parcels, state.selected_data_source_id, parcels)
      },
      SET_STATE(state, payload){
        for(const key in payload){
          Vue.set(state, key, payload[key])
        }
      },
      RESET_STATE(state){
        const currentDataSourceId = state.selected_data_source_id;
        const initialState = initial_state();
        
        // Reset everything except selected_data_source_id
        for(const key in initialState){
          if (key !== 'selected_data_source_id' && key !== 'order_prefixes') {
            Vue.set(state, key, initialState[key]);
          }
        }
        
        // Preserve the selected_data_source_id
        state.selected_data_source_id = currentDataSourceId;
      },
      UNSHIFT_PARCEL(state, payload){
        state.parcels.unshift(payload)
      }
}

const getters = {
  get_server_fulfillments: (state, getters, rootState) => {
    // return rootState.warehouse.company.data_sources.reduce((tot, ds) => tot += ds.fulfillments || 0, 0)
    return rootState.warehouse.company.data_sources.find(ds => ds.id === state.selected_data_source_id)?.fulfillments || 0
  },
  get_active_fulfillments: (state) => {
    return state.parcels[state.selected_data_source_id] || []
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
