<template>
 <vue-tel-input
                  v-model="phoneValue"
                  styleClasses="pt-2 pb-2 mb-8"
                  mode="international"
                  :dropdownOptions="{
                    showDialCodeInSelection: false,
                    showFlags: true,
                  }"
                  :inputOptions="{
                    placeholder: label,
                    autocomplete: 'on',
                    required,
                    label
                  }"
                  :validCharactersOnly="true"
                  @validate="validatePhone"
                  :enabledFlags="true"
                  :enabledFormatting="true"
                  :error-messages="phoneError ? ['Valid phone number is required'] : []"
                  @open="open = !open"
                  @close="open = !open"
                  @input="onPhoneInput"
                >
                <template v-slot:arrow-icon>
                  <span>{{ open ? '▲' : '▼' }}</span>
                </template></vue-tel-input>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'


export default {
  components: {
    VueTelInput
  },
  props: {
    value: {
      required: false,
      type: String,
      default: ''
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    label: {
      required: false,
      type: String,
      default: ''
    },
    placeholder: {
      required: false,
      type: String,
      default: ''
    }
  },
  computed: {
    
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        // Update internal state when value prop changes
        if (newVal && newVal !== this.phoneValue) {
          this.phoneValue = newVal
        }
      }
    },
  },
  data() {
    return {
      phoneValue: this.value,
      phoneError: false,
      open: false,
    }
  },
  methods: {
    validatePhone(validation) {
      this.phoneError = !validation.isValid;
    },
    onPhoneInput(number, obj){
      // We need to emit the formatted number to the parent
      if (obj && obj.number) {
        // No need to update phoneValue as v-model will handle that
        this.$emit('input', obj.number);
      } else if (obj?.formatted === '') this.$emit('input', obj.formatted)

    }
  },
  async mounted(){
    // Initialize with the value prop if it exists
    if (this.value) {
      console.log('TelInput mounted with value:', this.value);
      
      this.phoneValue = this.value;
      
      // Force a re-render after a short delay to ensure the component updates
      setTimeout(() => {
        if (this.phoneValue) {
          const temp = this.phoneValue;
          this.phoneValue = '';
          setTimeout(() => {
            this.phoneValue = temp;
          }, 10);
        }
      }, 100);
    }
  }
}
</script>
