import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/lib/locale/en';
// import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: 'en',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // Primary colors
        primary: '#4CAF50',
        'primary-light': '#80E27E',
        'primary-dark': '#087f23',
        
        // Secondary colors
        secondary: '#2E7D32',
        'secondary-light': '#60ad5e',
        'secondary-dark': '#005005',
        
        // Accent colors
        accent: '#03A9F4',
        'accent-light': '#67DAFF',
        'accent-dark': '#007AC1',
        
        // Semantic colors
        success: '#4CAF50',
        info: '#2196F3',
        warning: '#FFC107',
        error: '#FF5252',
        
        // Background colors
        background: '#f6f6f7',
        surface: '#FFFFFF',
        
        // Text colors
        'text-primary': '#212121',
        'text-secondary': '#757575',
        'text-disabled': '#BDBDBD',
      },
    },
  },
});
