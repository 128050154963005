<template>
  <v-app>
    <router-view v-if="!loading" />
    <div class="loading-container text-center mx-auto" v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
        width="5"
      />
      <p class="text-body-1 mt-4">{{ loading_message }}</p>
    </div>
    <SnackBar />
    <SwitchUserModal />
    <FloatingSupportChat />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    SnackBar: () => import('@/components/SnackBar.vue'),
    SwitchUserModal: () => import('@/components/SwitchUserModal.vue'),
    FloatingSupportChat: () => import('@/components/FloatingSupportChat.vue')
  },
  data() {
    return {
    
    }
  },
  computed: {
    ...mapState({
      loading_message: state => state.app.loading_message,
      loading: state => state.app.loading,
    })
  },
  async mounted(){
    this.$store.dispatch('app/initiate')
  }
}
</script>

<style lang="scss">
// Font definitions
@font-face {
  font-family: 'Boing'; 
  src: url('./assets/fonts/boing-regular.woff2');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

// Global styles
.v-application {
  font-family: 'Boing', 'Roboto', sans-serif !important;
  
  // Smooth scrolling
  scroll-behavior: smooth;
  
  // Better text rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  // Prevent horizontal overflow
  overflow-x: hidden;
  
  // Global link styling
  a {
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      text-decoration: none;
    }
  }
  
  // Card styling enhancements
  .v-card {
    border-radius: 12px;
    
    .v-card__title {
      font-weight: 500;
    }
  }
  
  // Button styling enhancements
  .v-btn {
    letter-spacing: 0.5px;
    text-transform: none;
    font-weight: 500;
    
    &.v-btn--rounded {
      border-radius: 50px !important;
    }
  }
  
  // Form field styling
  .v-text-field--outlined fieldset {
    border-radius: 8px;
  }
  
  // Transitions
  .fade-transition-enter-active,
  .fade-transition-leave-active {
    transition: opacity 0.3s ease;
  }
  
  .fade-transition-enter,
  .fade-transition-leave-to {
    opacity: 0;
  }
  
  // Animations
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  // Loading container
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
    .v-progress-circular {
      animation: pulse 2s infinite ease-in-out;
    }
  }
}
</style>
