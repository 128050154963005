import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import FirebaseAuthPlugin from './firebase/'
import firebase from 'firebase/app'
import 'firebase/auth'
import vuetify from './plugins/vuetify';
// import VueScrollTo from 'vue-scrollto'
import './plugins/base'
import { sync } from 'vuex-router-sync'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import Print from 'vue-print-nb'
import PromptPlugin from './plugins/prompt'

Vue.config.productionTip = false

Vue.use(FirebaseAuthPlugin)
Vue.use(Print)
Vue.use(PromptPlugin)
// Vue.use(VueScrollTo)

sync(store, router)

let app
// , root_user, user_timestamp

firebase.auth().onAuthStateChanged(async user => {
  // if(app && root_user && user){ 
  //   // const now = new Date().getTime()
  //   if(root_user.uid !== user.uid && user_timestamp && (
  //     new Date().getTime() > user_timestamp + (1000 * 5)
  //   )) store.commit('app/SET_STATE', {show_session_expiration_dialog: true})
  // }
  // if(user){ 
  //   console.log('setting root user');
  //   root_user = user
  //   if(!user_timestamp) user_timestamp = new Date().getTime()
  // }
  console.log('authstatechange', user);
  store.commit('user/SET_USER', user)
  if(user) user.getIdTokenResult().then(res => 
    console.log('id_token_result', res))
  if(!app) {
    console.log('mounting app');
    app = new Vue({
      router,
      vuetify,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
