// import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore';

import Vue from "vue"

const initial_state = () => ({ 
    products: [],
    products_updated_at: '',
    loading_products: false,
})

const state = initial_state()


const actions = {
async get_specific_products({commit, rootState}, { uuids }){
  const refresh_products = this._vm.$functions.httpsCallable('refresh_products')
  const products = await refresh_products({ uuids, company_uid: rootState.warehouse.current_company_id }).then(res => res.data)
  commit('UPDATE_PRODUCTS', products)
  return products
},
 async get_products({ commit, rootState }, { refresh }){
  if(!rootState.warehouse.company.data_sources.length) return
   commit('SET_LOADING_PRODUCTS',  true)
   if(!rootState.warehouse.company.products_updated_at 
    || (new Date().getTime() - (1000 * 60 * 60 * rootState.warehouse.company.products_refresh_interval)) > new Date(rootState.warehouse.company.products_updated_at).getTime() 
    || refresh){
    const get_products = this._vm.$functions.httpsCallable('get_products_by_company')
    get_products({ company_uid: rootState.warehouse.current_company_id, refresh: true })
      .then(products => {
        commit('SET_PRODUCTS', products.data)
        commit('warehouse/SET_COMPANY', { products_updated_at: products.data.products_updated_at }, { root: true })
      })
      .finally(() => commit('SET_LOADING_PRODUCTS', false))
    } else {
      const data_sources = rootState.warehouse.company.data_sources.filter(ds => !!ds.subscription_plan && ds.fulfilling_company_id === rootState.warehouse.current_company_id)
      console.log('getting products data_sources', data_sources)
      const requests = data_sources
                .map(ds => firebase.firestore().collection('data_sources').doc(ds.id).collection('products').get()
                    .then(docs => {
                        const products = []
                        docs.forEach(doc => products.push(doc.data()))
                        return products
                    })
                )
            const products = await Promise.all(requests).then(p => [].concat(...p))
            commit('SET_PRODUCTS', { products })
            commit('SET_LOADING_PRODUCTS', false)
    }
 },
 async update_inventory({ getters, commit, dispatch }, { uuid, change }){
  const product = getters.get_product(uuid) || await dispatch('get_specific_products', {uuids: [uuid]})
   .then(products => 
     products.find(p => p.uuid === uuid)
     )
   const update_product_inventory = this._vm.$functions.httpsCallable('update_product_inventory')
   const { inventory_item_id, inventory_quantity, title } = product
   const inventory_products = await update_product_inventory({ uuid, change, inventory_item_id, inventory_quantity, title }).then(res => res.data)
   commit('warehouse/SET_COMPANY', { inventory_products }, {root: true})
 }
//  async refresh_products({ commit }, product_uuids){

//  },
}

const mutations = {
  SET_LOADING_PRODUCTS(state, loading){
    Vue.set(state, 'loading_products', loading)
  },
  SET_PRODUCTS(state, payload){
    for(const key in payload){
      Vue.set(state, key, payload[key])
    }
  },
  UPDATE_PRODUCTS(state, products){
    for(const product in products){
      const index = state.products.findIndex(p => p.uuid === products[product].uuid)
      Vue.set(state.products, index, products[product])
    }
  },
  SET_INVENTORY(state, { uuid, inventory_quantity }){
    const index = state.products.findIndex(p => p.uuid === uuid)
    Vue.set(state.products, index, { 
      ...state.products[index],
      inventory_quantity,
    })
  },
  RESET_STATE(state){
    for(const key in initial_state()){
      Vue.set(state, key, initial_state()[key])
    }
  }
}

const getters = {
  get_product: state => uuid => {
    if(!state.products.length) return null
    const p = state.products.filter(product => product.uuid === uuid)
    if(!p.length) {
      // TODO Future: If product doesnt exist fetch it
      // Edit: Not needed, it fetches it on "lastPick"
      return null
    }
    const product = p[0]
    return product
  },
  get_product_inventory_alarm: (state, getters, rootState) => uuid => {
    if(rootState.warehouse.company.inventory_products.some(p => p.uuid === uuid)){
      if(new Date(rootState.warehouse.company.inventory_products.filter(p => p.uuid === uuid)[0].counted_at).getTime() >
      (new Date().getTime() - (1000 * 60 * 60 * 24 * rootState.warehouse.company.days_valid_inventory_count))) return false
    }
    const product = getters.get_product(uuid)
    return product?.inventory_quantity < rootState.warehouse.company.check_inventory_threshold
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}

