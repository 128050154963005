// import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/storage'
// import axios from 'axios'
import { uid } from 'uid'


import Vue from "vue"

const initial_state = () => ({ 
    order_bucket: [],
    // parcels: [],
    photos: [],
    orders: [],
    loading_parcels: [],
    fetching_orders: false,
    order_count: 0,
    total_remaining_picks: 0,
    starting_amount_picks: 0,
    did_check_abandoned: false,
    abandoned_buckets: []
})

const state = initial_state()


const actions = {
async set_parcel_error({ state, commit, dispatch }, { index, reset, skip_save, ...err }){
  const parcel = state.order_bucket[index]
  if(reset && !(parcel.errors || []).length) return
  const errors = reset ? [] : !(parcel.errors || []).some(e => e.message === err.message) ? (parcel.errors || []).concat([err]) : (parcel.errors || [])
  commit('SET_ORDER_ERRORS', { index, errors })
  if(!skip_save) await dispatch('save_order_bucket', 'set_parcel_error')
},
async remap_shipping_in_order_buckets({ commit }, { ds_id, company_id, exclude }){
  const remap_shipping_in_order_buckets = this._vm.$functions.httpsCallable('remap_shipping_in_order_buckets')
  const remap = await remap_shipping_in_order_buckets({ ds_id, company_id, exclude }).then(res => res.data)
  if(remap.change) commit('SET_ORDER_BUCKET', remap.order_bucket.orders)
},
async get_order_count({ commit, rootState }){
  const get_order_count = this._vm.$functions.httpsCallable('get_order_count')
  const order_count = await get_order_count({ company_id: rootState.warehouse.current_company_id }).then(res => res.data)
  commit('SET_STATE', { order_count })
},
async recover_bucket({ rootState },{ from_id }){
  const to_id = rootState.user.userProfile.id
  const company_id = rootState.warehouse.current_company_id
  const swap_bucket_id = this._vm.$functions.httpsCallable('swap_bucket_id')
  await swap_bucket_id( { from_id, to_id, company_id } )
},
async get_abandoned_buckets({ rootState, commit }){
  const get_abandoned_buckets = this._vm.$functions.httpsCallable('get_abandoned_buckets')
  const abandoned_buckets = await get_abandoned_buckets({company_id: rootState.warehouse.current_company_id }).then(res => res.data)
  if(!abandoned_buckets.length) commit('SET_STATE', { did_check_abandoned: true })
  else commit('SET_STATE', { abandoned_buckets})
},
 async get_orders({ commit, rootState, rootGetters, state }, {hard_refresh}){
  const company_uid = rootState.warehouse.current_company_id
  const data_source_id = rootState.warehouse.company.data_sources[0]?.id
  commit('SET_FETCHING_ORDERS', true)
  const get_orders = this._vm.$functions.httpsCallable(process.env.VUE_APP_MODE === 'postnord' ? 'get_orders' : 'get_order_bucket')
  const {orders, total_remaining_picks, total_remaining_orders } = await get_orders({hard_refresh, company_uid, data_source_id}).then(orders => orders.data)
  for(const order of orders){
    order.line_items = order.line_items.map(item => {
      item.price = (item.product_id === 7531838406828 ? '39.99' : item.price)
      return {
        ...item,
        sf_quantity_to_fulfill: item.fulfillable_quantity,
        grams: item.grams || rootGetters['product/get_product'](item.uuid)?.grams || 0,
        // TODO rootgetter will fail if product is newly added
      }
    })
    order.total_weight = order.total_weight || order.line_items.reduce((tot, item) => tot + (item.grams * item.fulfillable_quantity), 0)
  }
  if(total_remaining_picks){
    const starting_amount_picks = (state.starting_amount_picks < total_remaining_picks) ? total_remaining_picks : state.starting_amount_picks
    commit('SET_STATE', { total_remaining_picks, starting_amount_picks })
  }
  if(total_remaining_orders) commit('SET_STATE', { order_count: total_remaining_orders })
  if(process.env.VUE_APP_MODE === 'postnord') commit('SET_STATE', { orders })
  else commit('SET_ORDER_BUCKET', orders)
  commit('SET_FETCHING_ORDERS', false)
  // dispatch('listen_on_bucket')
  // commit('SET_PICK_ORDER', order_bucket)
 },
async check_bucket_inventory({ getters, dispatch, rootGetters }){
  const products_to_check = getters.get_pick_order
    .filter(item => item.last_pick || !rootGetters['product/get_product'](item.uuid))
    .map(item => item.uuid)
  if(!products_to_check.length) return
  await dispatch('product/get_specific_products', { uuids: products_to_check }, {root: true })
    // const refresh_products = this._vm.$functions.httpsCallable('refresh_products')
    // const products = await refresh_products({ uuids: products_to_check, company_uid: rootState.warehouse.current_company_id }).then(res => res.data)
    // commit('product/UPDATE_PRODUCTS', products, {root: true})
},
 async fulfill_parcel({ state, dispatch, commit, rootState }, {index, seconds_spent}){
  commit('SET_STATE', { loading_parcels: state.loading_parcels.concat([index]) } )
  const parcel = state.order_bucket[index]
  // const order_uid = parcel.uuid
  if(index < (state.order_bucket.length - 1)){
    Vue.set(state.order_bucket, index, { 
      ...state.order_bucket[index], 
      sf_fulfilled: true,
    })
  }
  const available_until_d = new Date().setDate(new Date().getDate() + 48)
  const available_until = new Date(available_until_d).toISOString()
  parcel.seconds_spent = seconds_spent || 0
  parcel.sf_label_refs = parcel.sf_data.labels.map(p => {
    return {
      type: p.description, 
      media: p.media, 
      available_until,
      ref: p.ref,
    }
  })
  parcel.sf_photo_refs = (state.photos[index] || []).map(() => {
    return {
      available_until,
      ref: `data_sources/${parcel.sf_data_source_id}/fulfillment_photos/${uid(24)}.jpeg`
    }
  })

  if(parcel.sf_data?.shipping_service) parcel.sf_data.shipping_service.carrier_tracking_url_pattern = rootState.app.config.carriers[
    rootState.warehouse.company.carriers.find(carrier => carrier.id === parcel.sf_data.shipping_service.sf_carrier_account_id).type
  ]?.carrier_tracking_url_pattern || parcel.sf_data.shipping_service.carrier_tracking_url_pattern || ''

  const fulfill_parcel = this._vm.$functions.httpsCallable('fulfill_parcel')
  const [{ fulfillment, fulfillments }, ] = await Promise.all([
    fulfill_parcel({ parcel })
    .then(res => res.data)
    .catch(e => {
      Vue.set(state.order_bucket, index, { 
        ...state.order_bucket[index], sf_fulfilled: false
      })
      dispatch('app/submit_error', { e, within: 'fulfill_parcel', text: `ORDER: \n${JSON.stringify(parcel, undefined, 3)}`}, {root: true})
      throw new Error(e)})
      ,
      // parcel.sf_label_refs.map((p, parcel_index) => {
      //   if(state.parcels[index][parcel_index] && state.parcels[index][parcel_index].data){
      //     return firebase.storage().ref().child(p.ref).put(state.parcels[index][parcel_index].data)
      //   } else return null
      // }),
      parcel.sf_photo_refs.map((p, photo_index) => { // ÄR INTE DETTA FEL?? 2025-04
        return firebase.storage().ref().child(p.ref).put(state.photos[index][photo_index])
      })
  ])
  commit('SET_STATE', { loading_parcels: state.loading_parcels.filter(i => i !== index) } )
  if(!fulfillment) return
  Vue.set(state.order_bucket, index, { 
    ...state.order_bucket[index], sf_fulfilled: true
  })
  const ds_id = parcel.sf_data_source_id
  const ds_name = rootState.warehouse.company.data_sources.find(d => d.id === ds_id).name
  commit('fulfillments/UNSHIFT_PARCEL', {...fulfillment, sf_ds_name: ds_name }, { root: true })
  const order_count = state.order_count -= 1
  commit('warehouse/SET_DATA_SOURCE', { id: ds_id, fulfillments }, { root: true })
  if(order_count > -1) commit('SET_STATE', { order_count })
  await dispatch('save_order_bucket', 'fulfill_parcel')
 },
 async check_parcel_fulfilled({ state, dispatch }, index){
  const parcel = state.order_bucket[index]
  const order_uid = parcel.uuid
  const get_one_order = this._vm.$functions.httpsCallable('get_one_order')
  const order = await get_one_order({ order_uid }).then(res => res.data)
  const unfulfilled = parcel.line_items.reduce((tot, item) => {
    const new_item = order.line_items.filter(i => i.id === item.id)[0]
    if(!new_item) return tot
    return tot += (new_item.fulfillable_quantity - item.removed_quantity)
  }, 0)
  if(unfulfilled) dispatch('set_parcel_error', {
    index,
    message: `${unfulfilled} line items in this order are unfulfilled in your store. Fulfill in Shopify to proceed.`,
    type: 'warning'
  })
  else {
    Vue.set(state.order_bucket, index, { 
      ...state.order_bucket[index], sf_fulfilled: true
    })
    dispatch('save_order_bucket')
  }
 },
 drop_parcel({ state, dispatch }, index){
  Vue.set(state.order_bucket, index, { 
    ...state.order_bucket[index], sf_dropped: true
  })
  const order = state.order_bucket[index]
  if(order.sf_data.shipping_service && order.sf_data.shipping_service.type === 'shopify' && order.sf_data.labels.length){
    const void_shopify_label = this._vm.$functions.httpsCallable('void_shopify_label')
    void_shopify_label({ shopify_label_id: order.sf_data.labels[0].shopify_label_id, data_source_id: order.sf_data_source_id })
  }
  dispatch('save_order_bucket')
  // dispatch('block_order', index)
 },
 async block_order({ rootState, state, commit }, index){
  const block_order = this._vm.$functions.httpsCallable('block_order')
  const company_uid = rootState.warehouse.current_company_id
  const order = state.order_bucket[index]
  // const message = getters.get_parcel_errors(index).map(e => e.message).toString()
  const message = (order.errors || []).map(e => e.message).join(', ')
  const data_source_id = order.sf_data_source_id
  const blocked_orders = await block_order({order, company_uid, message, data_source_id}).then(res => res.data)
  if(blocked_orders) commit('warehouse/SET_DATA_SOURCE', { id: data_source_id, blocked_orders }, {root: true})
 },
 async submit_item({ getters, state, dispatch, commit, rootState }, { index, seconds_spent }){
  const bucket = getters.get_pick_order[index]
  const total_picks = bucket.orders.reduce((tot, order) => tot + order.quantity - order.removed_quantity, 0)
  let picked_items = 0
  const promises = []
  for(const order of bucket.orders){
    const order_index = state.order_bucket.findIndex(i => i.uuid === order.order_uuid)
    // console.log(state.order_buckets.filter(i => i.uuid === order.order_uuid).length)
    state.order_bucket[order_index].line_items.forEach(item => {
      if(item.product_id === bucket.product_id && item.variant_id === bucket.variant_id && item.id === order.line_item_id){
        // item.fulfillable_quantity -= order.quantity
        item.fulfilled_quantity += (order.quantity - order.removed_quantity)
        item.seconds_spent = Math.round((seconds_spent * ((order.quantity - order.removed_quantity) / total_picks)) * 100) / 100
        picked_items += (order.quantity - order.removed_quantity)
      }
    })
    commit('DEDUCT_PICKS', picked_items)
    commit('SET_ORDER_BUCKET', state.order_bucket)
    if(
      !state.order_bucket[order_index].line_items.some(item => item.fulfillable_quantity - item.fulfilled_quantity - item.removed_quantity)
      && state.order_bucket[order_index].line_items.some(item => item.fulfilled_quantity
      && !(
        state.order_bucket[order_index].line_items.some(item => item.removed_quantity && !item.override_parcel_block) 
        && !rootState.warehouse.company.data_sources.find(ds => ds.id === state.order_bucket[order_index].sf_data_source_id).allow_partial_fulfillment
      )
      && state.order_bucket[order_index].shipping_lines.length 
      && state.order_bucket[order_index].sf_data.shipping_service
      && !state.order_bucket[order_index].sf_data.labels?.length
      )
    ) {
      console.log('requesting print on ', state.order_bucket[order_index].name)
      promises.push(new Promise(resolve => {
        console.log('in promise')
        commit('SET_STATE', { loading_parcels: state.loading_parcels.concat([order_index]) } )
        dispatch('warehouse/request_parcel',{ index: order_index, is_silent: true }, { root: true }).then(async () => {
          if(!order_index) await dispatch('warehouse/request_print', order_index, { root: true })
          commit('SET_STATE', { loading_parcels: state.loading_parcels.filter(i => i !== order_index )})
          return resolve()
        })
      })
    )
        
        }
  }
  console.log('promises', promises)
  await Promise.all(promises)
  await dispatch('save_order_bucket', 'submit_item')
 },
 remove_item({ state, dispatch, rootState, commit }, {order, item, reason, override_default, amount }){
   console.log('remove_item, ORDER:', order)
   let picked_items = 0
  const order_b = state.order_bucket[state.order_bucket
    .findIndex(i => i.uuid === order.order_uuid)]
    const data_source = rootState.warehouse.company.data_sources.filter(ds => ds.id === order_b.sf_data_source_id)[0]
    const allow_partial_fulfillment = override_default ? !data_source.allow_partial_fulfillment : data_source.allow_partial_fulfillment;
    order_b.line_items.forEach(i => {
    // om bolaget inte tillåter partial fulfillment - ta bort allt!
    if(!allow_partial_fulfillment){
      i.removed_quantity = i.fulfillable_quantity
      i.override_parcel_block = override_default
      picked_items += i.fulfillable_quantity
    } else if(i.uuid === item.uuid) {
      i.removed_quantity += amount
      i.override_parcel_block = override_default
      picked_items += amount
    }
  })
  commit('DEDUCT_PICKS', picked_items)
  dispatch('save_order_bucket')
  dispatch('block_item', { item, data_source_id: data_source.id, reason })
 },
 async save_order_bucket({ rootState, state }, from){
  console.log('saveOrderBucket', from)
  // try {
  const db = firebase.firestore()
  const company_ref = db.collection('companies').doc(rootState.warehouse.current_company_id)
  console.log('STATE', state.order_bucket)
  await db.runTransaction(async t => {
    const company = await t.get(company_ref).then(res => res.data())
    const bucket = company.order_buckets
      .filter(bucket => bucket.user_id === rootState.user.user.uid)[0]  
      console.log('company.order_bucket', company.order_buckets)
      console.log('bucket', bucket)
    bucket.orders = state.order_bucket
    bucket.last_updated_at = new Date().toISOString()
    bucket.total_remaining_picks = state.total_remaining_picks
    const set = { order_buckets: company.order_buckets.filter(bucket => bucket.user_id !== rootState.user.user.uid).concat(bucket) }
    t.update(company_ref, set)
  })
  console.log('done saveOrderBucket', from)
  // } catch (e) {
  //   console.log('Error in save order bucket', e, set);
  // }
 },
 async block_item({ rootState, commit }, { item, data_source_id, reason }){
  const block_item = this._vm.$functions.httpsCallable('block_item')
  const company_uid = rootState.warehouse.current_company_id
  const blocked_items = await block_item({item, company_uid, data_source_id, reason }).then(res => res.data)
  // if(blocked_items.data) commit('warehouse/SET_COMPANY', {blocked_items: blocked_items.data}, {root: true})
  if(blocked_items) commit('warehouse/SET_DATA_SOURCE', { id: data_source_id, blocked_items }, { root: true })
 },
 async refresh_order_bucket({ commit, rootState, dispatch }){
  commit('RESET_STATE')
  // commit('SET_FETCHING_ORDERS', true)
  const company_uid = rootState.warehouse.current_company_id
  // await dispatch('save_order_bucket')
  await dispatch('get_orders', { hard_refresh: true, company_uid })
 },
}

const mutations = {
  DEDUCT_PICKS(state, amount){
    const picks = state.total_remaining_picks - amount
    Vue.set(state, 'total_remaining_picks', picks < 0  ? 0 : picks)
  },
  SET_ORDER_BUCKET(state, payload){
    Vue.set(state, 'order_bucket', payload)
  },
  SET_ORDER_ERRORS(state, { index, errors }){
    console.log(index, errors)
    if(!state.order_bucket[index]) return
    Vue.set(state.order_bucket[index], 'errors', errors)
  },
  SET_ORDER(state, { index, key, value }){
    // const [ first, second ] = key.split('.')
    // Vue.set(state.order_bucket[index][first], second, value )
    console.log(state.order_count)
    const keys = key.split('.')
    const final_key = keys.splice(-1)
    if(eval(`state.order_bucket[${index}].${keys.join('.')}.${final_key}`) instanceof Array) {
      eval(`state.order_bucket[${index}].${keys.join('.')}.${final_key}`).push(value)
    } 
    else Vue.set(eval(`state.order_bucket[${index}].${keys.join('.')}`), final_key, value)
  },
  SET_LINE_ITEM(state, { index, item_index, line_item }){
    Vue.set(state.order_bucket[index], item_index, line_item)
  },
  SET_STATE(state, payload){
    for(const key in payload){
      Vue.set(state, key, payload[key])
    }
  },
  SET_LOADING_PARCEL(state, {index, done}){
    Vue.set(state, 'loading_parcels', done ? state.loading_parcels.filter(s => s !== index ): state.loading_parcels.concat([index]))
  },
  SET_SF_DATA(state, payload){
    Vue.set(state.order_bucket, payload.index, {
      ...state.order_bucket[payload.index],
      sf_data: {
          ...state.order_bucket[payload.index].sf_data,
          ...payload.data,
      }
    })
  },
  // SET_ORDER_IN_BUCKET(state, { order_index, ...payload}){
  //   Vue.set(state.order_)
  // },
  REMOVE_PHOTO(state, {order_index, photo_index}){
    // const x = state.photos[order_index]
    // x.
    state.photos[order_index].splice(photo_index, 1)
  },
  SET_PHOTOS(state, payload){
    const x = (state.photos[payload.order_index] || []).concat([payload.photo])
    // x[payload.parcel_index] = payload
    Vue.set(state.photos, payload.order_index, 
      x
      )
  },
  SET_FETCHING_ORDERS(state, e){
    Vue.set(state, 'fetching_orders', e)
  },
  RESET_STATE(state){
    for(const key in initial_state()){
      Vue.set(state, key, initial_state()[key])
    }
  }
}

const getters = {
  get_pick_order: (state, getters) => {
    // const shelves = rootState.warehouse.shelves
    const items = state.order_bucket
      .reduce((tot, order) => tot.concat(...order.line_items
        .filter(item => {
          if(!item.fulfillable_quantity) return false
          if(tot.findIndex(i => i.uuid === item.uuid) !== -1){
            tot[tot.findIndex(i => i.uuid === item.uuid)].orders.push({ 
              line_item_id: item.id,
              order_uuid: order.uuid, 
              quantity: item.fulfillable_quantity, 
              fulfilled_quantity: item.fulfilled_quantity || 0,
              removed_quantity: item.removed_quantity,
              active: !!(item.fulfillable_quantity - item.fulfilled_quantity - item.removed_quantity),
            })
            return false
          }
          return true
        })
        .map(item => {
          return {
          ...item,
          orders:[{ 
            line_item_id: item.id,
            order_uuid: order.uuid, 
            quantity: item.fulfillable_quantity,
            fulfilled_quantity: item.fulfilled_quantity || 0,
            removed_quantity: item.removed_quantity,
            active: ((item.fulfillable_quantity - item.fulfilled_quantity - item.removed_quantity) > 0),
          }],
        }})), [])
      .filter(item => item && (item.fulfillment_status === null || item.fulfillment_status === 'partial'))
      .map(item => {
        const { uuid, name, orders, product_id, variant_id } = item
        const last_pick = orders.reduce((tot, order) => tot + order.quantity, 0) === item.sf_total_unfulfilled
        const position = getters.get_item_position(uuid)
        return {
          uuid,
          name, 
          orders,
          position,
          product_id,
          variant_id,
          last_pick,
        }
      })
      .sort((a, b) => {
        if(!a.position) return 1
        if(!b.position) return -1
        else if(a.position.shelve_index < b.position.shelve_index){ return -1 }
        else if(a.position.shelve_index > b.position.shelve_index) { return 1 }
        else {
          if(a.position.shelf < b.position.shelf){ return -1}
          else if(a.position.shelf > b.position.shelf){ return 1}
          else {
            if(a.position.slot < b.position.slot) return -1
            else if(a.position.slot > b.position.slot) return 1
            else {
              if(a.position.product < b.position.product) return -1
              else if(a.position.product > b.position.product) return 1
              else return 0
            }
          }
        }
      })
      return items
  },
  get_item_position: (state, getters, rootState) => (uuid) => {
    const shelves = rootState.warehouse.shelves
    for(const shelve in shelves){
      for(const shelf in shelves[shelve].shelfs){
        for(const slot in shelves[shelve].shelfs[shelf].slots){
          for(const product in shelves[shelve].shelfs[shelf].slots[slot].products){
            
            if(shelves[shelve].shelfs[shelf].slots[slot].products[product].uid === uuid) return {
              shelve: shelves[shelve].name || +shelve + 1,
              shelve_index: +shelve,
              shelf: parseInt(shelf),
              slot: parseInt(slot),
              product: parseInt(product),
              printed: shelves[shelve].shelfs[shelf].slots[slot].products[product].printed,
            }
          }
        }
      }
    }

    return null
  },
  get_order_bucket_position: (state) => (uuid) => {
    return state.order_bucket.findIndex(order => order.uuid === uuid)
  },
  get_current_item: (state, getters) => {
    if(!state.order_bucket || !state.order_bucket.length) return 0
    const index = getters.get_pick_order.findIndex(i => 
      i.orders.some(order => order.active)
    )
    return index
  },
  get_current_parcel_card: (state, getters) => {
    if(getters.get_current_item !== -1) return null
    return state.order_bucket.findIndex(a => !a.sf_fulfilled && !a.sf_dropped && a.line_items.some(item => item.fulfilled_quantity))
  },
  // get_printable_orders: (state, getters, rootState) => {
  //   const printable = state.order_bucket.filter(order => {
  //     const data_source = rootState.warehouse.company.data_sources.filter(ds => ds.id === order.sf_data_source_id)[0]
  //     if(!order.line_items.some(item => item.fulfilled_quantity)) return false
  //     if(data_source.allow_partial_fulfillment) return true
  //     if(order.line_items
  //       .some(item => rootState.warehouse.company.blocked_items
  //         .some(blocked => blocked.item_uuid === item.uuid)
  //       )) return false
  //     return true 
  //   })
  //   return printable
  // },
  // get_parcel_errors: (state) => (index) => {
  //   return state.errors.filter(err => err.index === index)
  // },
  get_return_items: (state, getters) => {
    return state.order_bucket.filter(order => order.sf_dropped)
      .reduce((tot, order) => tot.concat(
        order.line_items
          .filter(item => item.fulfilled_quantity))
      , [])
      .map(item => ({
        ...item,
        sf_position: getters.get_item_position(item.uuid)
      }))
      .sort((a,b) => !a.sf_position ? 1 : 
                    !b.sf_position ? -1 : a.sf_position.shelve - b.sf_position.shelve)
  },
  get_data_source_by_bucket_order: (state, getters, rootState) => order_uuid => {
    const order_b = state.order_bucket[state.order_bucket
      .findIndex(i => i.uuid === order_uuid)]
      const data_source = rootState.warehouse.company.data_sources.find(ds => ds.id === order_b.sf_data_source_id)
      return data_source
  },
  get_order_by_uuid: (state) => order_uuid => {
    return state.order_bucket
      .find(i => i.uuid === order_uuid)
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
