<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-text class="pt-4">{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-for="(button, index) in buttons"
          :key="index"
          color="primary"
          text
          @click="handleClick(button.value)"
        >
          {{ button.text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PromptDialog',
  data() {
    return {
      dialog: false,
      text: '',
      buttons: [],
      resolve: null,
      reject: null
    }
  },
  methods: {
    open(options) {
      this.dialog = true
      this.text = options.text || ''
      this.buttons = options.buttons || [{ text: 'OK', value: { ok: true } }]
      
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    handleClick(value) {
      this.dialog = false
      if (this.resolve) {
        this.resolve(value)
      }
    }
  },
  beforeDestroy() {
    // Clean up to prevent memory leaks
    this.resolve = null
    this.reject = null
  }
}
</script>
